// 登录接口
import {
	axios
} from '../utils/http.js'

const UserMangApi = {
	UserList: '/v3/User/List',//用户管理列表
	UserADel: '/v3/User/ADel',//用户管理列表/权限管理列表删除
	UserAdd: '/v3/User/Add',//新增用户
	UserAEdit: '/v3/User/AEdit',//编辑用户
	UserEntry: '/v3/User/Entry',//无钥匙登录
}


//用户管理列表
export function UserList(data) {
	return axios({
		url: UserMangApi.UserList,
		method: 'post',
		data:data
	})
}

//用户管理列表/权限管理列表删除
export function UserADel(data) {
	return axios({
		url: UserMangApi.UserADel,
		method: 'post',
		data:data
	})
}

//新增用户
export function UserAdd(data) {
	return axios({
		url: UserMangApi.UserAdd,
		method: 'post',
		data:data
	})
}

//编辑用户
export function UserAEdit(data) {
	return axios({
		url: UserMangApi.UserAEdit,
		method: 'post',
		data:data
	})
}
//编辑用户
export function UserEntry(data) {
	return axios({
		url: UserMangApi.UserEntry,
		method: 'post',
		data:data
	})
}