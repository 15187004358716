<template>
	<div class="binding">
		<div class="binding-T">
			<div class="binding-TL">
				<Breadcrumb class="bre"></Breadcrumb>
				<div class="binding-searchs">
					<inputs @inputs="inputValue" :inputShow="true" inputPlace="请输入名称查询数据"></inputs>
					<div class="binding-tit-mai1-btn">
						<el-button type="danger" icon="el-icon-search">查询</el-button>
					</div>
				</div>
			</div>
			<div class="binding-TR">
				<div class="binding-searchsMai">
				</div>
			</div>
		</div>
		<div class="binding-B">
			<div class="binding2">
				<el-table :data="tableData" border style="width: 100%" size="small">
					<template slot="empty">
						<noData></noData>
					</template>
					<el-table-column prop="Admin_uuid" label="编号" width="300" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column prop="Admin_name" label="名称" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column prop="Admin_user" label="账号" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column prop="Power_name" label="权限" width="150" :show-overflow-tooltip="true">
					</el-table-column>
					<!-- <el-table-column prop="Admin_telephone" label="电话" width="120">
					</el-table-column>
					<el-table-column prop="Admin_mail" label="邮箱">
					</el-table-column> -->
					<el-table-column label="性别" width="60" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							{{scope.row.Admin_gender===1?'男':scope.row.Admin_gender===2?'女':'未知'}}
						</template>
					</el-table-column>
					
					<!-- <el-table-column prop="Admin_text" label="备注">
					</el-table-column> -->
					<el-table-column label="创建时间" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							{{scope.row.CreateTime | Time(scope.row.CreateTime)}}
						</template>
					</el-table-column>
					<el-table-column prop="address" label="操作" :show-overflow-tooltip="true">
						<el-dropdown split-button type="danger" size="mini" @command="allpir($event,item)">
							操作
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="a"><i class="el-icon-wallet"
										style="font-size: 18px;"></i>设备绑定</el-dropdown-item>
								<el-dropdown-item command="b"><i class="el-icon-edit-outline"
										style="font-size: 18px;"></i>复制</el-dropdown-item>
								<el-dropdown-item command="c"><i class="el-icon-delete"
										style="font-size: 16px;"></i>粘贴</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</el-table-column>
				</el-table>
				<div class="binding-pagination">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
						:current-page="pages.page" :page-size="pages.page_z"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
		
		<el-dialog title="设备绑定" :visible.sync="dialogVisible" :close-on-click-modal="false" :append-to-body="true">
			<div class="NewUser" style="height: 600px;overflow: hidden;">
				<el-tabs tab-position="top">
					<el-tab-pane label="已设备绑定">
						<div class="binding-mod">
							<div class="binding-mod-search">
								<div class="binding-mod-search-L" style="display: flex;">
									<div class="binding-mod-search-L1">
										<el-input placeholder="请输入主机名称" v-model="input" size="small" clearable>
										</el-input>
									</div>
									<div class="binding-mod-search-L1" style="margin: 0 10px 10px 10px;">
										<el-input placeholder="请输入主机SN" v-model="input" size="small" clearable>
										</el-input>
									</div>
									<div class="binding-mod-search-L1">
										<el-button type="primary" icon="el-icon-search" size="small">搜索</el-button>
									</div>
								</div>
								<div class="binding-mod-search-R">
									<!-- 右边内容 -->
								</div>
							</div>
							<div class="binding-mod-cont">
								<el-table :data="tableDatas" stripe style="width: 100%" border>
									<template slot="empty">
										<noData></noData>
									</template>
									<el-table-column prop="date" label="主机名" width="180" :show-overflow-tooltip="true">
									</el-table-column>
									<el-table-column prop="name" label="SN" width="180" :show-overflow-tooltip="true">
									</el-table-column>
									<el-table-column label="操作" :show-overflow-tooltip="true">
										<el-button type="danger" icon="el-icon-delete" size="small">删除</el-button>
									</el-table-column>
								</el-table>
							</div>
							<div class="binding-mod-pag">
								<el-pagination layout="prev, pager, next" :total="1000">
								</el-pagination>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="设备库">
						<div class="binding-mod">
							<div class="binding-mod-search" style="display: flex;">
								<div class="binding-mod-search-L" style="display: flex;">
									<div class="binding-mod-search-L1">
										<el-input placeholder="请输入主机名称" v-model="input" size="small" clearable>
										</el-input>
									</div>
									<div class="binding-mod-search-L1" style="margin: 0 10px 10px 10px;">
										<el-input placeholder="请输入主机SN" v-model="input" size="small" clearable>
										</el-input>
									</div>
									<div class="binding-mod-search-L1">
										<el-button type="primary" icon="el-icon-search" size="small">搜索</el-button>
									</div>
								</div>
								<div class="binding-mod-search-R"  style="margin-left:10px;">
									<el-button type="primary" icon="el-icon-upload" size="small">导入</el-button>
								</div>
							</div>
							<div class="binding-mod-cont">
								<el-table :data="tableDatas" stripe style="width: 100%" border>
									<template slot="empty">
										<noData></noData>
									</template>
									<el-table-column prop="date" label="主机名" width="180" :show-overflow-tooltip="true">
									</el-table-column>
									<el-table-column prop="name" label="SN" width="180" :show-overflow-tooltip="true">
									</el-table-column>
									<el-table-column label="操作" :show-overflow-tooltip="true">
										<el-button type="primary" icon="el-icon-link" size="small">绑定</el-button>
									</el-table-column>
								</el-table>
							</div>
							<div class="binding-mod-pag">
								<el-pagination layout="prev, pager, next" :total="1000">
								</el-pagination>
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		UserList
	} from '../../api/userManagement.js'
	import {PowerList} from '../../api/RightsManagement.js'
	export default {
		data() {
			return {
				pages: {
					T_name: '',
					page: 1,
					page_z: 5,
				},
				total: 0,
				input:'',
				dialogVisible: false,
				Page4: 5, //当前页
				loading: false,
				formInline: {
					user: '',
					region: ''
				},
				tableDatas:[{
				  date: '网络版库房采集器',
				  name: 'KF868131063451805',
				}, {
				  date: '网络版库房采集器',
				  name: 'KF868131063451805',
				}, {
				  date: '网络版库房采集器',
				  name: 'KF868131063451805',
				},{
				  date: '2网络版库房采集器',
				  name: 'KF868131063451805',
				}, {
				  date: '网络版库房采集器',
				  name: 'KF868131063451805',
				}, {
				  date: '网络版库房采集器',
				  name: 'KF868131063451805',
				}, {
				  date: '网络版库房采集器',
				  name: 'KF868131063451805',
				}],
				tableData: []
			}
		},
		mounted() {
			this.GetuserlistApi()
		},
		filters: {
			Time: function(time) { //时间戳转换
				if (time) {
					var date = new Date(time); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
					let y = date.getFullYear();
					let MM = (date.getMonth() + 1).toString().padStart(2, '0');
					let d = date.getDate().toString().padStart(2, '0');
					let h = date.getHours().toString().padStart(2, '0');
					let m = date.getMinutes().toString().padStart(2, '0');
					let s = date.getSeconds().toString().padStart(2, '0');
					return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
				} else {
					return '0000-00-00  00-00-00'
				}
			},
		},
		methods: {
			GetuserlistApi() {
				UserList(this.pages).then(res => {
					////console.log('用户列表', res)
					if (res.data.Code === 200) {
						this.GetpowerListApi(res.data.Data.Data)
						this.total = res.data.Data.Num
					}
				})
			},
			GetpowerListApi(data) {
				var arr = data
				var that = this
				PowerList({
					T_name: '',
					page: 1,
					page_z: 999999
				}).then(res => {
					////console.log('权限列表', res)
					var powArr = res.data.Data.Data
					if (res.data.Code === 200 || powArr.lenght > 0) {
						this.power = res.data.Data.Data
						arr.forEach(function(obj) {
							const b = powArr.find(item => item.Id == obj.Admin_power)
							that.$set(obj, 'Power_name', b.Power_name)
							that.$set(obj, 'Power_id', b.Id)
						})
					}
					that.tableData = arr
					////console.log(that.tableData)
				})
			},
			inputValue(e){
				////console.log(e)
			},
			handleSizeChange(e) { //每页多少条
				this.pages.page_z = e
				this.GetuserlistApi()
			},
			handleCurrentChange(e) { //跳转页
				this.pages.page = e
				this.GetuserlistApi()
				////console.log(e)
			},
			allpir(i, item) {
				if (i === 'a') { //无钥匙登录
					////console.log('处理', i, item)
					this.dialogVisible = true
				} else if (i === "b") { //同步分类
					////console.log('处理记录', i, item)
					this.$message.success('复制成功')
				} else if (i === "c") { //同步大屏
					////console.log('删除', i, item)
					this.$message.success('粘贴成功')
				}
			},
			onSubmit() {
				////console.log('submit!');
			}
		}
	}
</script>

<style lang="scss">
	.el-dropdown-menu--mini .el-dropdown-menu__item {
		line-height: 35px;
		padding: 0 10px;
		font-size: 12px;
	}

	.binding {
		user-select: none;
		.binding-mod {
			.binding-mod-pag{
				margin-top: 14px;
			}
			.binding-mod-cont {
				overflow-y: auto;
				height: 450px;
			}
			.binding-mod-search {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 10px 0;

				.binding-mod-search-L {
					display: flex;
					align-items: center;
					.binding-mod-search-L1 {
						margin-right: 10px;
					}
				}
			}
		}

		.binding-T {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: rgba(#fff, 1);
			height: 70px;
			padding: 15px 10px;
			box-shadow: 5px 5px 10px -10px #000;

			.binding-TL {
				display: flex;
				align-items: center;

				.binding-searchs {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: 20px;

					.binding-tit-mai1-btn {
						
					}

				}
			}

			.binding-searchsMai {
				display: flex;
				align-items: center;
				cursor: pointer;

				.binding-searchsMai1 {
					height: 30px;
					display: flex;
					align-items: center;
					color: #F56C6C;
					border-radius: 30px;
					background: #F56C6C;
					color: #fff;
					padding: 0 10px;
					font-size: 12px;
					border: 1px solid #ea6868;
				}

				.binding-searchsMai1q {
					background: #e6a23c;
					border: 1px solid #e6a23c;
					margin-right: 10px;
				}
			}
		}

		@media screen and (max-width: 1215px) {
			.binding-T {
				display: flex;
				flex-direction: column;
				background: rgba(#fff, 1);
				height: 80px;
				box-shadow: 5px 5px 10px -10px #000;

				.binding-TL {
					width: 100%;
				}

				.binding-TR {
					width: 100%;
					display: flex;
				}
			}
		}

		@media screen and (max-width: 1017px) {
			.bre {
				display: none;
			}

			.binding-T {
				display: flex;
				flex-direction: column;
				background: rgba(#fff, 1);
				height: 80px;
				box-shadow: 5px 5px 10px -10px #000;

				.binding-TL {
					width: 100%;

					.binding-searchs {
						margin-left: 0;
					}
				}

				.binding-TR {
					width: 100%;
					display: flex;
				}

			}
		}

		.binding2 {
			background: rgba(#fff, 1);
			overflow: hidden;
			margin: 10px;
			padding: 20px;
		}

		.binding-pagination {
			padding:20 20px 20px 0;
			margin: 10px 10px 10px 0;
			border-radius: 2px;
		}
	}
</style>
