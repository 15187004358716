// 登录接口
import {
	axios
} from '../utils/http.js'

const UserMangApi = {
	PowerList: '/v3/Power/List',//权限管理列表
	PowerEdit: '/v3/Power/Edit',//权限管理编辑
	PowerAdd: '/v3/Power/Add',//权限管理添加
	PowerDel: '/v3/Power/Del',//权限管理删除
	
	MenuAll: '/v3/Menu/List_All',//权限树选择
	PowerAll: '/v3/Power/List_All',//权限选择
	WarningTypeAll: '/v3/WarningType/List_All',//报警权限选择
	PowerGet: '/v3/Power/Get',//编辑获取状态
	
}
//权限树选择
export function PowerGet(data) {
	return axios({
		url: UserMangApi.PowerGet,
		method: 'post',
		data:data
	})
}
//权限树选择
export function MenuAll(data) {
	return axios({
		url: UserMangApi.MenuAll,
		method: 'post',
		data:data
	})
}
//权限选择
export function PowerAll(data) {
	return axios({
		url: UserMangApi.PowerAll,
		method: 'post',
		data:data
	})
}
//报警权限选择
export function WarningTypeAll(data) {
	return axios({
		url: UserMangApi.WarningTypeAll,
		method: 'post',
		data:data
	})
}

//权限管理列表
export function PowerList(data) {
	return axios({
		url: UserMangApi.PowerList,
		method: 'post',
		data:data
	})
}

//权限管理编辑
export function PowerEdit(data) {
	return axios({
		url: UserMangApi.PowerEdit,
		method: 'post',
		data:data
	})
}
//权限管理添加
export function PowerAdd(data) {
	return axios({
		url: UserMangApi.PowerAdd,
		method: 'post',
		data:data
	})
}
//权限管理删除
export function PowerDel(data) {
	return axios({
		url: UserMangApi.PowerDel,
		method: 'post',
		data:data
	})
}


